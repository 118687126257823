<template>
	<div class="kesearch ms-auto ms-xl-1 me-xl-auto">
		<b-dropdown
			ref="dropdownSearch"
			variant="link"
			offset="-630"
			no-caret
			class="button-search"
		>
		<template #button-content>
			<span class="visually-hidden">Suche</span>
		</template>
			<form
				ref="keSearch"
				@submit.prevent="fetchKeSearch(); submitClick();"
			>
				<Autocomplete />
			</form>
		</b-dropdown>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import Autocomplete from '~/components/atoms/forms/autocomplete/Autocomplete'
export default {
	name: 'Search',
	components: {
		Autocomplete
	},
	methods: {
		...mapActions([
			'fetchKeSearch'
		]),
		submitClick(){
			this.$router.push({path: '/suche'});
			this.$refs.dropdownSearch.hide();
		},
	},
}
</script>

<style lang="scss" scope>
@import '~@/assets/scss/common';
$kesearch-header-width: 41rem !default;
$kesearch-header-icon-size: 20px !default;
$kesearch-header-icon-size-sm: 18px !default;
$kesearch-header-margin: 25px !default;
$tx-kesearch-pi1-button-size: 50px !default;
$tx-kesearch-pi1-icon-size: 30px !default;

.button-search-before {
	content: "";
	position: absolute;
	width: $kesearch-header-icon-size;
	height: $kesearch-header-icon-size;
	background-size: $kesearch-header-icon-size;
	@include media-breakpoint-up(xl) {
		top: 0;
		left: 0;
		margin-top: -13px;
	}
}

.kesearch {
	ul.dropdown-menu {
		z-index: 0;
			@include media-breakpoint-down(xl) {
			width: 100%;
			background: #fff;
			top: 60px !important;
			padding: 10px 0;
		}
	}

	.dropdown-menu {
		padding: 0;
		top: $kesearch-header-icon-size !important;
		@include media-breakpoint-down(xl) {
			position: static;
			z-index: 0;
		}
	}

	.dropdown,
	.dropdown-menu {
		padding: 0;
		border-radius: 50rem;
		margin-top: $kesearch-header-icon-size * 1.5;

		@include media-breakpoint-down(xl) {
			position: static;
			transform: none !important;
			width: 100%;
			border-radius: 0;
			margin-top: 0;
			box-shadow: none;
		}
	}

	.dropdown-toggle-no-caret:after {
		border-top: 0;
		border-right: 0;
	}

	.button-search .btn:not(.btn-tertiary) {
		position: relative;
		padding: 0;
		height: $kesearch-header-icon-size;

		@include media-breakpoint-up(xl) {
			top: - $kesearch-header-icon-size;
			font-size: 1.5rem;
		}
	}
	.button-search {

		&:before {
			@extend .button-search-before;
			background: url('data:image/svg+xml,#{svg-magnifier($white)}') center center no-repeat;
			background-size: $kesearch-header-icon-size;

			@include media-breakpoint-up(xl) {
				background: url('data:image/svg+xml,#{svg-magnifier($primary)}') center center no-repeat;
				background-size: $kesearch-header-icon-size;
			}
		}

		&.show {
			&:before {
				@extend .button-search-before;
				background: url('data:image/svg+xml,#{svg-close($white)}') center center no-repeat;

				@include media-breakpoint-up(xl) {
					background: url('data:image/svg+xml,#{svg-close($primary)}') center center no-repeat;
					background-size: $kesearch-header-icon-size;
				}
			}
		}
	}

	@include media-breakpoint-down(xl) {
		.b-dropdown {
			background: $secondary;
			width: $kesearch-header-icon-size;
			height: $kesearch-header-icon-size;
			padding: 10px;
			border-radius: 5rem;
			display: flex;
			align-items: center;
			top: 0;

			svg {
				color: #fff;
				font-size: 16px;
				margin: 0;
				width: $kesearch-header-icon-size-sm;
				height: $kesearch-header-icon-size-sm;
			}

			&:hover {
				background: $primary;
			}
		}
	}

	.tx-kesearch-pi1 {
		width: $kesearch-header-width;
		padding: 0;

		.input-group {
			.form-control {
				padding-left: 2rem;
			}
			.btn {
				height: $kesearch-header-margin;
				margin: 10px;
				top: 0;
			}
		}

		@include media-breakpoint-down(xl) {
			width: 95%;
			margin: 0 auto;
		}

		.input-group-append > :first-child {
			border-radius:$border-radius;
			border: none;

			@include media-breakpoint-down(md) {
				display: flex;
				align-items: center;
				border-radius:100%;
				width: $tx-kesearch-pi1-icon-size;
				height: $tx-kesearch-pi1-icon-size;
				padding: 10px;
				margin: 0;

				&:after {
					content: "";
					position: absolute;
					background: url('data:image/svg+xml,#{svg-magnifier($white)}') center center no-repeat;
					width: $tx-kesearch-pi1-icon-size;
					height: $tx-kesearch-pi1-icon-size;
				}
			}
		}

		.hit {
			color: $secondary;
		}

		.vbt-autcomplete-list {

			a.vbst-item {
				display: block;
				width: 100%;
				z-index: 1;
			}

			.ke-results-autcomplete-list {
				padding: 20px 25px 0 20px;
				border-bottom: 1px solid $border-color;
			}

			.ke-results-title {
				z-index: -1;
				position: relative;
				padding: 0;
			}
			.ke-results-teaser {
				line-height: 28px;
				color: $isd--grey-dark;
			}

			@include media-breakpoint-down(md) {
				margin: 20px 20px 0 15px;
			}

			width: 91% !important;
			margin: 0 20px 0 30px;
			background: #fff;
			top: 60px;
			z-index: -1;
			border-bottom-left-radius: 1rem!important;
			border-bottom-right-radius: 1rem!important;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			overflow-x: hidden;
		}
	}
}
</style>
