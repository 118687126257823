<template>
	<div class="map-container">
		<div class="map-content d-flex justify-content-center align-items-center">
				<!-- search-box -->
			<div class="page-footer--seach-box" :class="isOpened ? 'is-opened' : ''">

				<button type="button" class="btn btn--primary page-footer--seach-box-btn" @click="toggle" aria-label="Suche Öffnen">
					<font-awesome-icon :icon="[
						'fal',
						'chevron-right'
					]" />
				</button>

				<div class="page-footer--seach-box-content">
					<header class="h5 mb-2">{{searchBox.header}}</header>
					<p>{{searchBox.text}}</p>
					<div class="mb-3 mt-2 page-footer--seach-box-input">
						<input
							type="text"
							:placeholder="searchBox.inputPlaceholder"
							name="search_location"
							class="form-control"
							id="search_location_footer"
						/>
						<input id="address_footer" name="address" value='' type="hidden" placeholder="">
						<div class="input-group-append">
							<button
								type="button"
								class="btn btn-primary"
								onclick="searchLocation()"
								aria-label="Suchen"
							>
								<font-awesome-icon
									id="button-niederlassungssuche"
									:icon="[
										'isd',
										'standort'
									]"
								/>
								<span
									class="visually-hidden"
								>
									Niederlassung Suchen
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- /search-box -->

			<!-- gmap -->
			<gmap-map
				ref="mapRef"
				class="vue-map-container"
				:center="userLocation"
				:zoom="10"
				:options="{
					streetViewControl: false,
					rotateControl: false,
					scrollwheel: false,
					fullscreenControl: false,
					zoomControlOptions: {
						position: 6
					},
				}">
			</gmap-map>
			<!-- /gmap> -->
		</div>
	</div>
</template>

<script>
if (process.client) {
  	require('./uc-block.bundle.js')
}
const axios = require('axios');
export default {
	name: 'PageFooterGoogleMap',
	data() {
		return {
			isOpened: true,
			searchBox: {
				header: 'Finden Sie uns in Ihrer Nähe',
				text: 'Wir sind bundesweit vor Ort – starten Sie jetzt Ihre Niederlassungssuche.',
				inputPlaceholder: 'PLZ oder Ort',
			},
			inifoWindowStatic: {
				inputPlaceholder: 'PLZ oder Ort',
			},
			userLocation: {
				lat: null,
				lng: null
			}
		}
	},
	async created() {
		await this.loadUserLocation();
	},
	methods: {
		async loadUserLocation() {
			this.userLocation = await this.$store.getters.userLocation(this.$config.env);
		},
		toggle() {
			this.isOpened = !this.isOpened;
		},
		handleResize() {
			this.isOpened = window.matchMedia('(max-width: 980px)').matches ?
				this.isOpened = false : this.isOpened = true
		},
		destroyed() {
			window.removeEventListener('resize', this.handleResize)
		},
	},
	computed: {
		iframeEnabled(){
			return this.cookies.enabled.filter(c =>{return c.name === 'functional'}).length > 0
		}
	},
	mounted() {
		// usercentrics optin google maps
		uc.reloadOnOptIn('S1pcEj_jZX');
		uc.reloadOnOptOut('S1pcEj_jZX');
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
			this.$refs.mapRef.$mapPromise.then((map) => {
				var map;
				var markers = [];
				var marker, i;
				const url = `${this.$config.subsidiariesGoogleMapsUrl}ueber-uns/kontakt-niederlassungen/niederlassungen`
				var infowindow = new google.maps.InfoWindow();
				// Autocomplete options
				var options = {
					types: ['(regions)'],
					componentRestrictions: {
						country: ['de']
					},
				};
				// Custom Icon
				var icon = {
					url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDI1IDMyIj4KICA8ZyBmaWxsPSJub25lIj4KICAgIDxwYXRoIGZpbGw9IiMwMDRFQTAiIGQ9Ik0yNSwxMi41IEMyNSwyMy4zNjk1NjUyIDEyLjUsMzAuOTc4MjYwOSAxMi41LDMwLjk3ODI2MDkgQzEyLjUsMzAuOTc4MjYwOSAwLDIzLjM2OTU2NTIgMCwxMi41IEMwLDUuNTk2NDQ1NjUgNS41OTY0NDU2NSwwIDEyLjUsMCBDMTkuNDAzNTg3LDAgMjUsNS41OTY0NDU2NSAyNSwxMi41IFoiLz4KICAgIDxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSI1Ljk3OCIgZmlsbD0iI0ZGRiIvPgogIDwvZz4KPC9zdmc+Cg==",
				}
				var iconActive = {
					url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIzMSIgdmlld0JveD0iMCAwIDI1IDMxIj4KICA8ZyBmaWxsPSJub25lIj4KICAgIDxwYXRoIGZpbGw9IiNGRjJFNjciIGQ9Ik0yNSwxMi41IEMyNSwyMy4zNjk1NjUyIDEyLjUsMzAuOTc4MjYwOSAxMi41LDMwLjk3ODI2MDkgQzEyLjUsMzAuOTc4MjYwOSAwLDIzLjM2OTU2NTIgMCwxMi41IEMwLDUuNTk2NDQ1NjUgNS41OTY0NDU2NSwwIDEyLjUsMCBDMTkuNDAzNTg3LDAgMjUsNS41OTY0NDU2NSAyNSwxMi41IFoiLz4KICAgIDxjaXJjbGUgY3g9IjEyLjUiIGN5PSIxMi41IiByPSI1Ljk3OCIgZmlsbD0iI0ZGRiIvPgogIDwvZz4KPC9zdmc+Cg==",
				}
				axios.get(url)
				.then(function (response) {
				// Markers
				for (var i = 0; i < response.data.content.colPos0[1].content.data.content.subsidiaries.length; i++) {
					var obj = response.data.content.colPos0[1].content.data.content.subsidiaries[i];
					marker = new google.maps.Marker({
						position: new google.maps.LatLng(obj.position, obj.position),
						header: obj.header,
						city: obj.location.city,
						street: obj.location.city,
						zip: obj.location.zip,
						phone: obj.location.phone,
						email: obj.location.email,
						link:  obj.link,
						animation: google.maps.Animation.DROP,
						icon: icon,
						map: map
					});
					markers.push(marker);

					google.maps.event.addListener(marker, 'click', (function (marker, obj) {
						return function () {
							// Info Window
							infowindow.setContent(`
							<div class="info-window">
								<div class="info-window-header-top"><i class="isd-logo-sm"></i>
								<span class="window-header">
								<b class="window-header-headline">${obj.location.city}</b></span>
								</div><p>${obj.location.street}</p>
								<p>${obj.location.zip}, ${obj.location.city}</p>
								<p><b>Telefon:</b>
								<a href="tel:${obj.location.phone}" title="Telefon">${obj.location.phone}</a></p>
								<p><b>E-Mail:</b>
								<a href="mailto:${obj.location.email}" title="E-Mail">${obj.location.email}</a></p>
								<a class="btn btn-sm btn-tertiary mt-2 w-100"
								href="${obj.link.replace(/^\/api\/(.+)\.json(\?.+)?$/, '/$1$2')}"
								title="Zur Niederlassung">Zur Niederlassung</a>
								<a class="btn btn-sm btn-tertiary mt-2 w-100"
								href="https://maps.google.com/maps?daddr=${obj.location.street} ${obj.location.zip},
								${obj.location.city} ${obj.location.zip}" target="_blank"
								title="Route berechnen">Route berechnen</a>
							</div>`);

							// Set Active Marker
							for (var j = 0; j < markers.length; j++) {
								markers[j].setIcon(icon);
							}
							this.setIcon(iconActive)
							infowindow.open(map, marker);
						}
					})(marker, obj));
				}
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				})


				let mrk = [];
				// Autocomplete
				const input = document.getElementById('search_location_footer');
				const autocomplete = new google.maps.places.Autocomplete(input, options);
				autocomplete.addListener("place_changed", () => {
				const place = autocomplete.getPlace();
				document.getElementById("address_footer").value = JSON.stringify(place.address_components);
					// Clear out the old markers.
					mrk.forEach((marker) => {
						marker.setMap(null);
					});
					mrk = [];
					mrk.push(
						new google.maps.Marker({
							position: place.geometry.location,
							map: map
						})
					);
					searchLocation();
					mapContainer.scrollIntoView({ behavior: 'smooth', block: 'center' });
				});

				const mapContainer = document.querySelector('.map-container');


				// Select first result from Autocomplete
				enableEnterKey(input)

				function enableEnterKey(input) {

					// Store original event listener
					const _addEventListener = input.addEventListener

					const addEventListenerWrapper = (type, listener) => {
						if (type === 'keydown') {
							// Store existing listener function
							const _listener = listener
							listener = (event) => {
								// Simulate a 'down arrow' keypress if no address has been selected
								const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
								if (event.key === 'Enter' && !suggestionSelected) {
									const e = new KeyboardEvent('keydown', {
										key: 'ArrowDown',
										code: 'ArrowDown',
										keyCode: 40,
									})
									_listener.apply(input, [e])
								}
								_listener.apply(input, [event])
							}
						}
						_addEventListener.apply(input, [type, listener])
					}

					input.addEventListener = addEventListenerWrapper

				}


				// Geocorder
				var geocoder = new google.maps.Geocoder();
				window.searchLocation = function () {
					searchLocation();
				}


				// Distance and nearest marker selector
				function searchLocation() {
					var lat = '';
					var lng = '';
					var place = autocomplete.getPlace();
					var address = document.getElementById('address_footer').value = JSON.stringify(place.formatted_address);
					geocoder.geocode({
						'address': address
					}, function (results, status) {
						if (status === google.maps.GeocoderStatus.OK) {
							lat = results[0].geometry.location.lat();
							lng = results[0].geometry.location.lng();
							findClosestMarker(lat, lng);
						} else {
							alert("Geocode was not successful for the following reason: " + status);
						}
					});
						console.log(place.formatted_address)
				};

				// Calculate distance haversine formula
				function findClosestMarker(lat1, lon1) {
					var pi = Math.PI;
					var R = 6371; // equatorial radius
					var distances = [];
					var closest = -1;

					for (i = 0; i < markers.length; i++) {
						var lat2 = markers[i].position.lat();
						var lon2 = markers[i].position.lng();

						var chLat = lat2 - lat1;
						var chLon = lon2 - lon1;

						var dLat = chLat * (pi / 180);
						var dLon = chLon * (pi / 180);

						var rLat1 = lat1 * (pi / 180);
						var rLat2 = lat2 * (pi / 180);

						var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
							Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rLat1) * Math.cos(rLat2);
						var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
						var d = R * c;

						distances[i] = d;
						if (closest == -1 || d < distances[closest]) {
							closest = i;
						}
					}

					// (debug) The closest marker is:
					// console.log(markers[closest]);
					map.panTo(markers[closest].getPosition());
					map.setZoom(10);
					// Open InfoWindow
					new google.maps.event.trigger(markers[closest], 'click');
				}

			});
	}
}
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$page-footer--seach-box-content-width: rem(360) !default;
	$page-footer--seach-box-content-open: rem(390) !default;
	$page-footer--seach-box-content-open-sm: rem(310) !default;
	$page-footer--seach-box-height: 270px !default;
	$page-footer--form-control-height: 40px !default;
	$page-footer--search-button-height: 55px !default;
	$page-footer--search-button-font-size: rem(24) !default;
	$page-footer--seach-box-content-padding: 35px 40px !default;
	$page-footer--seach-box-btn-color: color(primary, base) !default;
	$page-footer--seach-box-btn-color-hover: color(secondary, base) !default;
	$map-container--height: 415px !default;


	.vue-map-container {
		width: 100%;
		height: 520px;
		overflow: hidden;
		position: relative;
	}
	.gmap-custom-marker-container {
		cursor: pointer;
	}
	.gm-style-mtc, .gm-style-mtc {
		display: none!important;
	}
	.gm-style .gm-style-iw-c {
		padding: 15px;

		@include media-breakpoint-down(md) {
			padding: 15px!important;
		}
	}

	.info-window {
		text-align: left;

		.isd-logo-sm {
			&:after {
				content: "";
				background: url('data:image/svg+xml,#{svg-isd-logo($primary)}') center 15px no-repeat;
				position: relative;
				background-size: contain;
				padding: rem(15);
				border: none;
				vertical-align: middle;
				margin: 0;
			}
		}
		&-header-top {
			margin-bottom: rem(20);
		}
		.window-header {
			display: inline-block;
			position: relative;
			top: 10px;

			&-headline {
				font-size: rem(18);
				color: $primary;
			}
		}

		a:not(.btn) {
			color: $primary;
		}
		p {
			margin-bottom: rem(5);
		}
		.btn-check:focus + .btn-tertiary, .btn-tertiary:focus {
			box-shadow: none;
			color: #fff;
		}
	}
	.gmap-custom-marker {
		font-size: $page-footer--search-button-font-size * 1.2;
		color: $page-footer--seach-box-btn-color;
	}

	.map-content {

		@include media-breakpoint-down(sm) {
			padding: 0 10px;
		}

		@include media-breakpoint-down(md) {
			flex-direction: column;
			text-align: center;

			.page-footer--seach-box {
				width: 100%;
				padding: 25px 10px 15px 10px;
   				border-top: 1px solid $border-color;

				.h5 {
					font-size: 1.2rem;
				}

				.page-footer--seach-box-btn {
					display: none;
				}
				.page-footer--seach-box-input {
					position: relative;

					.form-control {
						width: 90%;
						min-height: $page-footer--form-control-height;
						padding-right: 0;
						margin: 0 auto;
					}

					.input-group-append {
						display: none;
					}
				}
			}
		}
	}
	@include media-breakpoint-up(md) {
		.page-footer--seach-box {
			position: absolute;
			right: -15px;
			height: $page-footer--seach-box-height;
			background-color: color(white);
			@include shadow;
			transition: all .5s ease-in-out;
			z-index: 5;
			margin-right: -$page-footer--seach-box-content-open-sm;

			@include media-breakpoint-up(sm) {
				margin-right: -$page-footer--seach-box-content-open;
			}

			&-content {
				position: relative;
				display: flex;
				flex-direction: column;
				padding: $page-footer--seach-box-content-padding;
				text-align: left;
				width: $page-footer--seach-box-content-width - 5;

				@include media-breakpoint-up(sm) {
					width: $page-footer--seach-box-content-width;
				}

				.h4 {
					margin-bottom: 0;
				}
			}

			&.is-opened {
				margin-right: 0;

				.page-footer--seach-box-btn {
					.svg-inline--fa {
						transform: rotate(0deg)
					}
				}
			}

			.page-footer--seach-box-btn {
				position: absolute;
				left: -25px;
				top: 40%;
				background: $page-footer--seach-box-btn-color;
				width: $page-footer--search-button-height;
				height: $page-footer--search-button-height;
				font-size: rem(20);
				color: color(white);
				padding: 0;
				z-index: 5;

				.svg-inline--fa {
					vertical-align: text-bottom;
					transform: rotate(180deg)
				}

				&:hover {
					background: $page-footer--seach-box-btn-color-hover;
					color: color(white);
				}
			}

			.page-footer--seach-box-input {
				position: relative;

				.form-control {
					width: 90%;
					min-height: $page-footer--form-control-height;
					padding-right: 0;

					@include media-breakpoint-up(md) {
						padding-right: 1rem;
					}
				}

				.input-group-append {
					position: absolute;
					right: 0;

					@include media-breakpoint-up(sm) {
						top: -1px;
					}

					top: -5px;

					.btn {
						width: $page-footer--search-button-height;
						height: $page-footer--search-button-height;
						padding: 0;
						z-index: $zindex-dropdown;
						font-size: $page-footer--search-button-font-size;

						.svg-inline--fa {
							vertical-align: middle;
							font-size: 2rem;
						}
					}
				}
			}
		}
	}

</style>
